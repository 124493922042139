import { Links, Meta, Outlet, Scripts } from 'react-router'
import '~/tailwind.css'
import { Button } from '~/components/ui/button'

export default function App() {
  return (
    <html lang="tr" className="m-0 flex min-h-full min-w-full">
      <head>
        <Meta />
        <Links />
        <meta charSet="utf-8" />
      </head>
      <body className="m-0 flex min-h-full min-w-full p-0">
        {/* <div className="flex flex-col items-center justify-center p-12">
          <h1 className="mb-2 font-bold text-3xl">denoremix 0.3</h1>
          <Button>action</Button>
        </div>
        <div className="flex flex-col items-center justify-center p-12">
          <Outlet />
        </div> */}
        <Outlet />
        <Scripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  return <h1>Something went wrong</h1>
}

export function HydrateFallback() {
  return <div>Loading...</div>
}
